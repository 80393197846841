const routes = {
    home: '/',
    login: '/login',
    players: '/players',
    playerHistory: '/players/history',
    matches: '/matches',
    matchView: '/matches/view',
    funds: '/funds',
    fundView: '/funds/view',
    expense: '/expense',
    expenseView: '/expense/view'
};

export default routes;
