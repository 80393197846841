export const API_ACTIONS = {
    login: '/login',
    dashboard: '/dashboard',
    players: '/players/list',
    addPlayer: '/players/add',
    updatePlayer: '/players/update',
    matches: '/matches/list',
    addMatch: '/matches/add',
    updateMatch: '/matches/update',
    matchDetails: '/match-details/list',
    addMatchDetails: '/match-details/add',
    updateMatchDetails: '/match-details/update',
    listByPlayer: 'match-details/listByPlayer',
}   