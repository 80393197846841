import React from "react"
import { Container, Nav, Navbar } from 'react-bootstrap';
import routes from "../../constants/routes";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" sticky data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} to={routes.home}>Cric App</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link eventKey="1" as={Link} to={routes.home}>Home</Nav.Link>
                        <Nav.Link eventKey="2" as={Link} to={routes.players}>Players</Nav.Link>
                        <Nav.Link eventKey="3" as={Link} to={routes.matches}>Matches</Nav.Link>
                        {/* <Nav.Link eventKey="4" as={Link} to={routes.funds}>Funds</Nav.Link> */}
                        {/* <Nav.Link eventKey="5" as={Link} to={routes.expense}>Expense</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}