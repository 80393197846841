import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from '../utils/lazy-loader';
import routes from '../constants/routes';

export function AppRoutes() {
    const appRoutes = [
        {
            path: '/',
            children: [
                {
                    index: true,
                    element: lazyLoadRoutes('dashboard'),
                },
                {
                    path: routes.login,
                    element: lazyLoadRoutes('auth/login', false),
                },
                {
                    path: routes.players,
                    element: lazyLoadRoutes('players/list'),
                },
                {
                    path: routes.matches,
                    element: lazyLoadRoutes('matches/list'),
                },
                {
                    path: `${routes.matchView}/:matchId`,
                    element: lazyLoadRoutes('matches/view'),
                },
                {
                    path: `${routes.playerHistory}/:id`,
                    element: lazyLoadRoutes('players/matches'),
                }
            ]
        },
        {
            path: '*',
            element: lazyLoadRoutes('not-found')
        }
    ];

    return useRoutes(appRoutes);
}